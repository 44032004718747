html {
  overflow-x: hidden;
}

body {
  /* letter-spacing: 0.1em; */
  letter-spacing: 0.15em;
}

@font-face {
font-family: '__NotoSansJP_128efb';
src: url(/_next/static/media/857f80ccfab5f8e2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__NotoSansJP_128efb';
src: url(/_next/static/media/7db862de4f9849c3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__NotoSansJP_128efb';
src: url(/_next/static/media/477e0f2693c22fb5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
}@font-face {font-family: '__NotoSansJP_Fallback_128efb';src: local("Arial");ascent-override: 110.26%;descent-override: 27.38%;line-gap-override: 0.00%;size-adjust: 105.20%
}.__className_128efb {font-family: '__NotoSansJP_128efb', '__NotoSansJP_Fallback_128efb', system-ui, arial
}.__variable_128efb {--noto-sans-jp: '__NotoSansJP_128efb', '__NotoSansJP_Fallback_128efb', system-ui, arial
}

@font-face {
font-family: '__Overpass_c6590a';
src: url(/_next/static/media/cd2e9c412e3f2420-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__Overpass_c6590a';
src: url(/_next/static/media/6aa9fbad9f33b18d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
}@font-face {font-family: '__Overpass_Fallback_c6590a';src: local("Arial");ascent-override: 83.05%;descent-override: 36.02%;line-gap-override: 0.00%;size-adjust: 106.33%
}.__className_c6590a {font-family: '__Overpass_c6590a', '__Overpass_Fallback_c6590a', system-ui, arial
}.__variable_c6590a {--overpass: '__Overpass_c6590a', '__Overpass_Fallback_c6590a', system-ui, arial
}

